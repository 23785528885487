import 'bootstrap'
import '@popperjs/core'
import 'bootstrap/js/dist/tooltip';
import 'fonts/icons/erficons.font.js'
import '../css/app.scss'


function closeNotification() {
	if (document.getElementById('close') != null) {
		document.getElementById('notification').classList.toggle('show')
		setTimeout(function () {
			if (document.getElementById('notification') !== null) {
				document.getElementById('notification').remove()
			}
		}, 600)
	}
}

let notificationTimeout = setTimeout(function () {
	closeNotification()
}, 6000)

if (document.getElementById('close') != null) {
	document.getElementById('close').addEventListener('click', function () {
		clearTimeout(notificationTimeout)
		closeNotification()
	})
}



